<template>
  <div class="box">
    <div class="boxLeft">
      <div>
        <span class="title">人才专区</span>
        <span class="message">您有一条新的招聘信息请查收！</span>
      </div>
      <div class="list" v-loading="leftLoading">
        <div class="item" v-for="(item, index) in leftList" :key="index">
          <div class="item_left">
            <img :src="icons[index]" alt class="img" />
            <div class="adress pointer" @click="handleLeft(item.id)">
              {{
                item.name
              }}
            </div>
          </div>
          <span
            class="item_right date"
          >{{ item.city_name_en | priorFormat(item.city_name_zh, LOCALE) }} | {{ item.experience | experienceFormat(LOCALE) }} | {{ item.level_require | levelTypeFormat(LOCALE) }}</span>
        </div>
        <noDataImg v-if="!leftLoading && leftList.length === 0"></noDataImg>
      </div>
    </div>
    <div class="boxRight">
      <div class="title">
        <span>走访交流</span>
        <div class="moreBox pointer" @click="handleMore">
          <span class="more">更多</span>
          <i class="el-icon-arrow-right colore3954f"></i>
        </div>
      </div>
      <div class="boxRightContent" v-loading="rightLoading">
        <div v-if="rightList.length > 0">
          <div class="imageBox">
            <al-image
              :src="rightList[0].img_url ? rightList[0].img_url : banner"
              class="img"
              fit="cover"
            ></al-image>
          </div>
          <div class="imageContent">
            <span
              class="pointer"
              @click="handleRightItem(rightList[0])"
            >{{ rightList[0].title | fullTextFormat(50) }}</span>
          </div>
          <div class="date">{{ rightList[0].created_time | secondFormat("LL") }}</div>
        </div>
        <noDataImg v-if="!rightLoading && rightList.length === 0"></noDataImg>
      </div>
    </div>
    <div class="messagess">
      <div class="formbox">
        <div class="ticps">如果您在使用本网站时有任何意见或建议，请写下您的意见或者建议。我们会尽快答复您，感谢您的关注~</div>
        <div class="name_tel">
          <el-input
            size="small"
            class="input"
            v-model="evaluateVal.name"
            placeholder="名字"
            style="width: 150px"
          />
          <el-input
            size="small"
            class="input1"
            type="number"
            v-model="evaluateVal.phone"
            placeholder="电话"
            style="width: 210px"
          />
        </div>
        <div class="email">
          <el-input size="small" class="input1" v-model="evaluateVal.email" placeholder="邮箱" />
        </div>
        <div>
          <el-input
            type="textarea"
            class="textarea"
            placeholder="请写下您想说的话"
            v-model="evaluateVal.content"
          />
        </div>
        <div class="btn">
          <el-button
            size="mini"
            type="primary"
            class="submit"
            @click="submit"
            :loading="btnLoading"
          >提交</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "schoolCooperation",
  props: {
    advertising3: {
      type: Object,
      default: null
    }
  },
  filters: {
    strings() {
      return '11111'
    }
  },
  data() {
    return {
      icons: [
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon1.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon2.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon3.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon4.png",
        "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/schoolCooperation/icon5.png",
      ],
      banner: "https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/company/activitybanner.png",
      leftList: [],
      centerList: [],
      rightList: [],
      leftLoading: false,
      centerLoading: false,
      rightLoading: false,
      evaluateVal: {
        name: "",
        email: "",
        phone: "",
        content: "",
      },
      btnLoading: false,
    }
  },
  mounted() {
    this.getLeftList()
    this.getCenterList()
    this.getRightList()
  },
  methods: {
    async getLeftList() {
      // 获取职位推荐
      this.leftLoading = true;
      let parmas = {
        start: 0,
        limit: 5,
        is_hot: 1,
        source: this.PJSource,
      }
      let data = await this.$store.dispatch("API_position/getJobPositionList", parmas);
      this.leftLoading = false;
      if (data.success) {
        this.leftList = data.data;
      }
    },
    async getCenterList() {
      this.centerLoading = true
      let params = {
        source: this.PJSource,
        nopage: 0,
        start: 0,
        limit: 5
      }
      let res = await this.$store.dispatch("API_index/memberCompanysearch", params);
      this.centerLoading = false
      if (res.success) {
        this.centerList = res.data
      }
    },
    async getRightList() {
      this.rightLoading = true
      let params = {
        content_type: 8,
        source: this.PJSource,
        nopage: 0,
        limit: 1,
        start: 0,
        is_show: 1,
        language: this.LOCALE === "en" ? 1 : 2,
      }
      let res = await this.$store.dispatch("API_index/getStaticContent", params);
      this.rightLoading = false
      if (res.success) {
        this.rightList = res.data
      }

    },
    goCompanyDetail(item) {
      let routeUrl = this.$router.resolve({
        path: "/companyDetail",
        query: {
          parameter: this._encode({
            company_id: item.company_id
          })
        }
      });
      window.open(routeUrl.href, "_blank");
    },
    handleLeft(id) {
      this.$router.push({
        path: "/positionDetail",
        query: {
          parameter: this._encode({
            id
          }),
        },
      });
    },
    handleMore() {
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: 8,
        },
      });
    },
    handleRightItem(item) {
      this.$router.push({
        path: "/detail",
        query: { id: item.id },
      });
    },
    async submit() {
      if (!this.IS_LOGIN) {
        this.$GLOBALEVENT.$emit("SHOWGLOBALLOGIN");
        return;
      }
      if (!this.evaluateVal.content) {
        this.$message.warning("输入建议不能为空");
        return;
      }
      this.btnLoading = true;
      this.evaluateVal.source = this.PJSource;
      let res = await this.$store.dispatch(
        "API_index/suggestionSave",
        this.evaluateVal
      );
      this.btnLoading = false;
      if (res.success) {
        this.$message.success("提交成功");
        this.evaluateVal = {};
      } else {
      }
    },
  }
}
</script>

<style scoped lang="less">
.box {
  margin-top: 36px;
  display: flex;
  width: 100%;
  min-height: 200px;
  padding-bottom: 38px;
  .boxLeft {
    width: 340px;
    margin-right: 25px;
    .title {
      color: #4a5a81;
      font-size: 24px;
      margin-right: 16px;
      font-weight: 800;
    }

    .message {
      font-size: 14px;
      color: #909399;
    }

    .list {
      position: relative;
      padding-top: 4px;
      min-height: 220px;

      .item {
        display: flex;
        justify-content: space-between;
        margin-top: 25px;

        .item_left {
          display: flex;
        }

        .img {
          margin-right: 10px;
        }

        .adress {
          color: #1f292e;
          margin-right: 10px;
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .item_right {
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .date {
          color: #999;
        }
      }
    }
  }

  .boxRight {
    width: 364px;

    .title {
      color: #4a5a81;
      font-size: 24px;
      font-weight: 800;
      width: 100%;
      display: flex;
      justify-content: space-between;
      .moreBox {
        padding: 5px 19px;
        font-size: 14px;
        color: #e3954f;
        border-radius: 15px;
        font-weight: 400;
      }
    }
    .boxRightContent {
      height: 200px;
      position: relative;
      .imageBox {
        margin-top: 20px;
        width: 364px;
        height: 142px;
        .img {
          width: 100%;
          height: 100%;
        }
      }
      .imageContent {
        margin-top: 6px;
        color: #7d8695;
        word-break: normal;
        height: 36px;
      }
      .date {
        margin-top: 4px;
        font-size: 14px;
        color: #7d8695;
      }
    }
  }
  .messagess {
    width: 450px;
    background: #4a5a81;
    margin-left: 25px;
    padding: 20px 32px 28px 20px;
    .ticps {
      font-size: 14px;
      font-weight: 400;
      color: #ffffff;
      line-height: 20px;
    }
    .name_tel {
      display: flex;
      justify-content: space-between;
      margin-top: 24px;
    }
    .email {
      margin: 16px 0;
    }
    .btn {
      margin-top: 16px;
      text-align: center;
      button {
        width: 115px;
        height: 30px;
      }
    }
  }
}

.pointer {
  cursor: pointer;
}

.color909399 {
  color: #909399;
}
</style>
