<template>
  <div class="creditBanner">
    <div class="creditBannerImg">
      <div
          v-if="advertising && advertising.img_url"
          class="bannerInner cp"
          :class="{'pointer': advertising.link_url && advertising.extend && !advertising.extend.is_show }"
          :style="{ 'background-image': 'url(' + advertising.img_url + ')' }"
          @click="handleDivLink(advertising)"
      >
        <div
            v-if="advertising.extend && advertising.extend.is_show"
            class="bannerBtn"
            :class="'btnStyle' + advertising.extend.buttonStyle"
            :style="{
                bottom: `${advertising.extend.bottom}%`,
                right: `${advertising.extend.right}%`,
                background: advertising.extend.backgroundColor,
                borderColor: advertising.extend.borderColor,
              }"
            @click="handleLink(advertising.link_url)"
        >
          <el-button
              type="text"
              :style="{
                  fontSize: `0.${advertising.extend.fontSize}rem`,
                  color: advertising.extend.color,
                }"
          >{{ advertising.extend.content }}
          </el-button
          >
        </div>
      </div>
      <div v-else class="nodata">暂无数据</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "advertising",
  data() {
    return {
      defaultImg: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/meng_5.png',
      advertising:null
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      let params = {}
      params.language = this.LOCALE === 'en' ? 1 : 2
      params.source = this.PJSource
      params.serial_no = 1
      let res = await this.$store.dispatch("API_index/getAdvertisingList", params)
      console.log(res)
      if (res.success) {
        this.advertising = res.data[0]
      }
      console.log(this.advertising)
    },
    handleLink(link) {
      if (!link) return
      if (link.indexOf("http") == "0") {
        window.open(link);
      } else {
        this.$router.push(link);
      }
    },
    handleDivLink(item) {
      if (item.link_url && item.extend && !item.extend.is_show) {
        this.handleLink(item.link_url)
      }
    },
  }
}
</script>

<style scoped lang="less">
.creditBanner {
  width: 100%;
  min-height:90px;
  position: relative;
  margin-bottom: 50px;

  .creditBannerImg {
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    .bannerInner {
      height: 100%;
      background-size: 100% 100%;
      position: relative;

      .bannerBtn {
        position: absolute;

        padding: 0px 20px;
        border: 1px solid;
        border-radius: 4px;
        transform: translate(50%, 50%);

        &.btnStyle1 {
          border-radius: 0px;
        }

        &.btnStyle2 {
          border-radius: 4px;
        }

        &.btnStyle3 {
          border-radius: 999px;
        }
      }
    }
  }
}

.nodata {
  width: 100%;
  height: 100%;
  background: #cccccc;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cp {
  cursor: pointer;
}
</style>