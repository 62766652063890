<template>
  <div class="dynamic" v-loading="loading">
    <div class="Information">
      <div class="top">
        <div class="left">
          行业信息
        </div>
        <div class="right blue" v-if="topData.length > 0" @click="goToList(2)">
          更多 >>
        </div>
      </div>
      <div class="bottom">
        <div class="item" v-for="item in topData" :key="item.id">
          <div class="item-left">
            <div class="left-title" @click="goToDetail(item)">
              {{ item.title|textFormat }}
            </div>
            <div class="left-time">
              {{ item.created_time|secondFormat('LL') }}
            </div>
          </div>
          <div class="item-right">
            <el-image
                style="width: 142px; height: 94px"
                :src="item.img_url||topDefaultImg"
                fit="cover"></el-image>
          </div>
        </div>
        <noDataImg v-if="!loading && topData.length === 0"></noDataImg>
      </div>
    </div>
    <div class="association">
      <div class="top">
        <div class="left">
          协会动态
        </div>
        <div class="right blue" v-if="middleData.length > 0" @click="goToList(3)">
          更多 >>
        </div>
      </div>
      <div class="bottom">
        <div class="item" v-for="item in middleData" :key="item.id">
          <div class="item-left">
            <div class="left-title" @click="goToDetail(item)">
              {{ item.title|textFormat }}
            </div>
            <div class="left-time">
              {{ item.created_time|secondFormat('LL') }}
            </div>
          </div>
          <div class="item-right">
            <el-image
                style="width: 142px; height: 94px"
                :src="item.img_url||middleDefaultImg"
                fit="cover"></el-image>
          </div>
        </div>
        <noDataImg v-if="!loading && middleData.length === 0"></noDataImg>
      </div>
    </div>
    <div class="member">
      <div class="top">
        <div class="left">
          协企交流
        </div>
        <div class="right blue" v-if="bottomData.length > 0" @click="goToList(8)">
          更多 >>
        </div>
      </div>
      <div class="bottom">
        <div class="item" v-for="item in bottomData" :key="item.id">
          <div class="item-left">
            <div class="left-title" @click="goToDetail(item)">
              {{ item.title|textFormat }}
            </div>
            <div class="left-time">
              {{ item.created_time|secondFormat('LL') }}
            </div>
          </div>
          <div class="item-right">
            <el-image
                style="width: 142px; height: 94px"
                :src="item.img_url||bottomDefaultImg"
                fit="cover"></el-image>
          </div>
        </div>
        <noDataImg v-if="!loading && bottomData.length === 0"></noDataImg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  //协会动态等等
  name: "dynamic",
  data() {
    return {
      loading: false,
      topData: [],
      middleData: [],
      bottomData: [],
      topDefaultImg:"https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/trf/active2.jpg",
      middleDefaultImg:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/siffa/index/activity/AssociationActivities.png',
      bottomDefaultImg:'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/contenttypeevent.png'

    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    async getList() {
      let res = await Promise.all([
        this.$store.dispatch("API_index/getStaticContent", {
          content_type: 2,
          start: 0,
          limit: 3,
          source: this.PJSource,
          language: this.LOCALE === "en" ? 1 : 2
        }),
        this.$store.dispatch("API_index/getStaticContent", {
          content_type: 3,
          start: 0,
          limit: 3,
          source: this.PJSource,
          language: this.LOCALE === "en" ? 1 : 2
        }),
        this.$store.dispatch("API_index/getStaticContent", {
          content_type: 8,
          start: 0,
          limit: 3,
          source: this.PJSource,
          language: this.LOCALE === "en" ? 1 : 2
        }),
      ])
      if (res[0] && res[0].success) {
        this.topData = res[0].data
      }
      if (res[1] && res[1].success) {
        this.middleData = res[1].data
      }
      if (res[2] && res[2].success) {
        this.bottomData = res[2].data
      }
      this.loading = false
    },
    goToList(type){
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: type,
        },
      });
    },
    goToDetail(item){
      this.$router.push({
        path: "/detail",
        query: {id: item.id},
      });
    }
  }
}
</script>

<style scoped lang="less">
.blue {
  color: #027FFF;
  cursor: pointer;
}

.dynamic {
  .Information, .association, .member {
    .top {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #DCDFE6;
      .left{
        font-size: 21px;
        font-weight: 800;
        color: #1F292E;
        display: block;
        width: 148px;
        height: 45px;
        line-height: 45px;
        position: relative;
        padding-left: 20px;
        &::before{
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border: 1px solid #DCDFE6;
          border-bottom: none;
          -webkit-transform: perspective(1em) scale(1.1, 1.3) rotateX(5deg);
          -webkit-transform-origin: bottom left;
        }
      }
    }

    .bottom {
      padding: 22px 0 32px 20px;
      display: flex;
      min-height: 140px;
      position: relative;

      .item {
        width: 368px;
        display: flex;
        margin-right: 28px;

        .item-left {
          width: 210px;
          margin-right: 16px;

          .left-title {
            width: 100%;
            height: 48px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            font-size: 18px;
            font-weight: bold;
            color: rgba(0, 0, 0, 0.65);
            margin-bottom: 21px;
          }

          .left-time {
            font-size: 14px;
            font-weight: 400;
            color: #3F8FF8;
          }
        }

        .item-right {
          width: 142px;
        }
      }
    }
  }
}
</style>