<template>
  <div class="information">
    <div class="title">
      <span>
        上海市报关协会
      </span>
      <span class="blue">
        资讯中心
      </span>
    </div>
    <div class="content">
      <div class="left" v-loading="leftLoading">
        <img :src="leftImgNew" alt="" @click="goToJoin">
      </div>
      <div class="right" v-loading="rightLoading">
        <img :src="rightImgNew" alt="" @click="goTolist">
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="300px">
      <div>
        <div class="openTitle">
          暂无企业信息, 请到控制台-企业设置 完善您的公司信息
        </div>
        <div class="tc open">
          完善企业信息<i class="el-icon-right cursor" @click="toConsole"></i>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="dialogVisible1" width="350px">
      <div>
        <div class="openTitle">您不是企业管理员，请联系企业管理员操作入会</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  //党建工作预归类
  name: "information",
  data() {
    return {
      leftLoading: false,
      rightLoading: false,
      leftData: [],
      rightData: [],
      leftImg: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/meng_8.png',
      rightImg: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/meng_7.png',
      leftImgNew: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/index/joinmeeting.png',
      rightImgNew: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/index/tenfourjoin.png',
      bottomImg: 'https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/airExpo/index/tenthreejoin.png',
      dialogVisible: false,
      dialogVisible1: false,//管理员
    }
  },
  mounted() {
    // this.getLeftList()
    // this.getRightList()
  },
  methods: {
    goToJoin(){
      if (this.estimateUserInfo(true, true, !this.USER_INFO.is_admin)) {
        this.$router.push("/memberShip")
      }
    },
    goTolist(){
      if (this.estimateUserInfo()) {
        this.$router.push("/preClass/applyFor")
      }
    },
    estimateUserInfo(sign=true, company=true, is_admin=false){
      if (!this.USER_INFO && sign) {
        this.$router.push({
          path: "/sign",
        });
        return false;
      }else if (!this.USER_INFO.company_id && company) {
        this.dialogVisible = true;
        return false;
      }else if (is_admin) {
        this.dialogVisible1 = true;
        return false;
      }else {
        return true;
      }
    },
    toConsole() {
      this.$router.push({path:"/console/company/basic"})
    },
    async getLeftList() {
      this.leftLoading = true
      let params = {}
      params.content_type = 13;
      params.start = 0
      params.limit = 2
      params.source = this.PJSource;
      params.language = this.LOCALE === "en" ? 1 : 2;
      let res = await this.$store.dispatch('API_index/getStaticContent', params)
      if (res.success) {
        this.leftData = res.data
        this.leftLoading = false
      }
    },
    async getRightList() {
      this.rightLoading = true
      let params = {}
      params.content_type = 14;
      params.start = 0
      params.limit = 2
      params.source = this.PJSource;
      params.language = this.LOCALE === "en" ? 1 : 2;
      let res = await this.$store.dispatch('API_index/getStaticContent', params)
      if (res.success) {
        this.rightData = res.data
        this.rightLoading = false
      }
    },
    goToMore(num) {
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: num,
        },
      });
    },
    goToDetail(item) {
      this.$router.push({
        path: "/detail",
        query: {id: item.id},
      });
    }
  }
}
</script>

<style scoped lang="less">
.blue {
  color: #027FFF;
}

.information {
  padding-top: 50px;

  .title {
    font-size: 36px;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.65);
    line-height: 50px;
    text-align: center;
    margin-bottom: 40px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    padding-bottom: 32px;

    .left, .right {
      width: 592px;
      cursor: pointer;

      .top {
        border-bottom: 1px solid #DCDFE6;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .heading {
          font-size: 21px;
          font-weight: 800;
          color: #1F292E;
          display: block;
          height: 0;
          width: 190px;
          border-width: 0px 30px 50px 0px;
          border-style: none solid solid;
          border-color: transparent transparent #D0DBE9;
          line-height: 50px;
          padding-left: 16px;

          .text {
            margin-left: 3px;
          }
        }

        .more {
          font-size: 14px;
          font-weight: 400;
          color: #027FFF;
          cursor: pointer;
        }
      }

      .bottom {
        .item {
          padding: 16px 0 0 16px;

          .item-title {
            display: flex;
            justify-content: space-between;
            margin-bottom: 8px;

            .item-heading {
              width: 300px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              font-size: 14px;
              font-weight: 400;
              color: rgba(0, 0, 0, 0.65);
              cursor: pointer;
            }

            .item-time {
              font-size: 12px;
              font-weight: 400;
              color: #3F8FF8;
            }
          }

          .item-content {
            width: 100%;
            font-size: 12px;
            font-weight: 400;
            color: #7D8695;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
    }

    .right {
      .top {
        .heading {
          width: 203px;
        }
      }

    }
  }
  .open {
    margin: 20px 0 0;
    i {
      color: #049DED;
      margin-left: 5px;
      border: 1px solid #049DED;
      border-radius: 50%;
    }
  }
}
.bottom{
  cursor: pointer;
}
</style>