<template>
  <div class="box">
    <div class="right">
      <div class="title">会员单位</div>
      <div class="list" v-loading="loading">
               <div class="imgSilder">
                 <el-carousel
                   :initial-index="value1"
                   :arrow="imgList.length > 1 ? 'always' : 'never'"
                   dots="none"
                   height="60"
                   indicator-position="none"
                   trigger="click"
                   :loop="true"
                 >
                   <el-carousel-item
                      v-for="(item, index) in imgList"
                      :key="index"
                      :name="index + ''"
                    >
                      <div class="demo-carousel clearfix">
                        <div
                          class="fl img-wrap"
                          v-for="(ele, index) in item"
                          :key="index"
                        >
                          <el-image
                            class="item-img"
                            :src="ele.img_url"
                            fit="scale-down"
                            @click="gotoWebsite(ele)"
                          >
                          </el-image>
                        </div>
                      </div>
                    </el-carousel-item>
                  </el-carousel>
                </div>
                <noDataImg v-if="imgList.length == 0 && !loading" />
      </div>
    </div>
  </div>
</template>

<script>
import {splitArr} from "~/baseUtils";

export default {
  name: "cooperation",
  data() {
    return {
      dataList: [],
      dataListWrap: [],
      imgList: [],
      showDialog: false,
      previewList: [],
      value1: 0,
      loading: false
    };
  },
  created() {
  },
  mounted() {
    this.vicePresidentList()
  },
  methods: {
    gotoWebsite(item) {
      window.open(item.link_url, "_blank")
    },
    async vicePresidentList() {
      try {
        this.loading = true
        let params = {}
        params.source = this.PJSource
        params.genre = 4
        params.nopage=1
        let res = await this.$store.dispatch("API_index/getunitTypeList", params)
        this.loading = false
        if (res.success) {
          this.dataListWrap = res.data
          this.imgList = splitArr(this.dataListWrap, 5)
        }
      } catch (e) {
        this.loading = false
      }
    },
  }
};
</script>

<style scoped lang="less">
.box {
  padding-top: 30px;

  .right {
    width: 100%;
  }

  .title {
    font-weight: 800;
    color: #192F59;
    font-size: 24px;
  }
  .list {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    .item {
      width: 220px;
      height: 60px;
      text-align: center;
      line-height: 60px;
      font-weight: 800;
      color: #FFFFFF;
      font-size: 24px;
    }
    .bg3DB166 {
      background: #E3954F;
    }
    .bg192F59 {
      background: #4A5A81;
    }
  }
  //.list {
    //margin-top: 30px;
    //height: 60px;
    //position: relative;
    //.item {
    //  width: 220px;
    //  height: 60px;
    //  background: #ffffff;
    //  margin-right: 25px;
    //  cursor: pointer;
    //}
    //.img {
    //  width: 100%;
    //}
  //}
}

.imgSilder {
  width: 100%;
  /deep/ .el-carousel {
    width: 100%;
  }

  /deep/ .el-carousel__arrow {
    display: none;
  }

  /deep/ .el-carousel__container {
    height: 60px;
    width: 100%;
  }

  .img-wrap {
    width: 220px;
    height: 60px;
    background: #fff;
    margin-right: 25px;

    .item-img {
      width: 100%;
      height: 100%;
      cursor: pointer;
    }
  }

  .img-wrap:nth-child(5) {
    margin-right: 0;
  }
}
</style>
