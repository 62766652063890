var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"creditBanner"},[_c('div',{staticClass:"creditBannerImg"},[(_vm.advertising && _vm.advertising.img_url)?_c('div',{staticClass:"bannerInner cp",class:{'pointer': _vm.advertising.link_url && _vm.advertising.extend && !_vm.advertising.extend.is_show },style:({ 'background-image': 'url(' + _vm.advertising.img_url + ')' }),on:{"click":function($event){return _vm.handleDivLink(_vm.advertising)}}},[(_vm.advertising.extend && _vm.advertising.extend.is_show)?_c('div',{staticClass:"bannerBtn",class:'btnStyle' + _vm.advertising.extend.buttonStyle,style:({
              bottom: `${_vm.advertising.extend.bottom}%`,
              right: `${_vm.advertising.extend.right}%`,
              background: _vm.advertising.extend.backgroundColor,
              borderColor: _vm.advertising.extend.borderColor,
            }),on:{"click":function($event){return _vm.handleLink(_vm.advertising.link_url)}}},[_c('el-button',{style:({
                fontSize: `0.${_vm.advertising.extend.fontSize}rem`,
                color: _vm.advertising.extend.color,
              }),attrs:{"type":"text"}},[_vm._v(_vm._s(_vm.advertising.extend.content)+" ")])],1):_vm._e()]):_c('div',{staticClass:"nodata"},[_vm._v("暂无数据")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }