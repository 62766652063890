<template>
  <div class="container">
    <!-- <div class="join_us" v-if="show_join_us">
      <div class="close" @click="show_join_us=false">
        <i class="el-icon-error"></i>
      </div>
      <img class="cursor"  @click="goApply" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/join_us.png" alt="">
      <span class="text cursor " @click="goApply">申请入会</span>
    </div> -->
    <carouselBanner paddingTop="29.4%" :bannerType="1"></carouselBanner>
    <div class="content_area">
      <!--      <activity />-->
      <!-- <div class="apply">快来加入我们吧，点击申请入会</div> -->
      <!--      <div class="apply">-->
      <!--        <img class="cursor" @click="handle(13)" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/yguilei.png" alt="">-->
      <!--        <img class="cursor" @click="handle(14)" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/dangjian.png" alt="">-->
      <!--      </div>-->
      <!--      <schoolCooperation/>-->
      <infomation />
      <advertisingSpace />
      <dynamic />
    </div>
    <ecology />
    <educationTraining />
    <recommend />
    <!-- dialogVisible -->
    <div class="wenjuanDialog" v-if="dialogVisible">
      <div class="tit">
          <!--<h1>证书申领</h1>-->
          <span class="el-icon-close" @click="dialogVisible=false"></span>
      </div>
      <img  src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scba-applets/logo/scbaappletcode.jpg" alt="">

    </div>
    <div class="diaoyanbox">
      <img @click="handleDiaoyan" src="https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scba-applets/diaoyan.png" alt="">
    </div>
  </div>
</template>

<script>
import carouselBanner from "@/baseComponents/carouselBanner";
import activity from "~scb/components/index/home/activity";
import schoolCooperation from "~scb/components/index/home/schoolCooperation";
import education from "~scb/components/index/home/education";
import risks from "~scb/components/index/home/risks";
import cooperation from "~scb/components/index/home/cooperation";

//8.2更改首页
import infomation from "~scb/components/index/home/information";
import advertisingSpace from "~scb/components/index/home/advertisingSpace";
import dynamic from "~scb/components/index/home/dynamic";
import ecology from "~scb/components/index/home/ecology";
import educationTraining from "~scb/components/index/home/educationTraining";
import recommend from "~scb/components/index/home/recommend";
export default {
  metaInfo: {
    title: "首页",
  },
  components: {
    carouselBanner,
    activity,
    schoolCooperation,
    education,
    risks,
    cooperation,
    infomation,
    advertisingSpace,
    dynamic,
    ecology,
    educationTraining,
    recommend,
  },
  data() {
    return {
      show_join_us: true,
      dialogVisible:true
    };
  },
  computed: {},
  created() {},
  methods: {
    handle(value) {
      this.$router.push({
        path: "/activitys",
        query: {
          content_type: value,
        },
      });
    },
    goApply() {
      this.$router.push("/memberShip");
    },
    goToOut(){
      window.open("https://jinshuju.net/f/uIDjOa?submit_link_generated_from=poster")
    },
    handleDiaoyan(){
      window.open('https://jinshuju.net/f/uuX5mi')
    }
  },
};
</script>
<style scoped lang="less">
.container {
  position: relative;
  background: #ffffff;
  .join_us {
    width: 200px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 200px;
    right: 0px;
    z-index: 30;
    .text {
      font-weight: bold;
      font-size: 20px;
    }
    img {
      width: 200px;
    }
    .close {
      text-align: right;
      width: 100%;
      i {
        display: inline-block;
        cursor: pointer;
      }
    }
  }
  .apply {
    margin-top: 30px;
    width: 100%;
    height: 120px;
    display: flex;
    justify-content: space-between;
    img {
      width: 590px;
    }
  }
  .education {
    width: 100%;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/homeBack.png");
    height: 480px;
    background-size: 100% 100%;
  }
  .cooperation {
    margin-top: 38px;
    width: 100%;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/homeBg2.png");
    height: 215px;
    background-size: 100% 100%;
  }
  .pointer {
    cursor: pointer;
  }
}
.wenjuanDialog{
  z-index: 20;
  //background: #fff;
  position: fixed;
  top: 25%;
  right: 15px;
  width: 200px;
  box-sizing: border-box;
  .tit{
    padding: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: 18px;
    font-weight: bold;
    span{
      cursor: pointer;
    }
  }
  img{
    width: 200px;cursor:pointer
  }
}
.diaoyanbox{
  z-index: 1000;
  position: fixed;
  right: 15px;
  top:calc(25% + 260px) ;
  img{
    width: 200px;
    cursor: pointer;
  }
}
</style>
